import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import JSZip from '@/utils/jszip.min.js'
import FileSaver from '@/utils/FileSaver.min.js'
//引用 html2Canvas
import html2Canvas from 'html2canvas';
// 请求列表
import api from '@/api/index'
// 自定义函数方法
class variable {
    // 将数组按照特定方式格式化
    sortArr(arr, str) {
        var _arr = [],
            _t = [],
            // 临时的变量
            _tmp;

        // 按照特定的参数将数组排序将具有相同值得排在一起
        arr = arr.sort(function (a, b) {
            var s = a[str],
                t = b[str];

            return s < t ? -1 : 1;
        });

        if (arr.length) {
            _tmp = arr[0][str];
        }
        // console.log( arr );
        // 将相同类别的对象添加到统一个数组
        for (var i in arr) {
            if (arr[i][str] === _tmp) {
                // console.log(_tmp)
                _t.push(arr[i]);
            } else {
                _tmp = arr[i][str];
                _arr.push(_t);
                _t = [arr[i]];
            }
        }
        // 将最后的内容推出新数组
        _arr.push(_t);
        return _arr;
    }
    // 提示
    msg(text, type) {
        ElementUI.Message({
            message: text,
            type: type || 'warning',
            customClass: "m-msg",
        });
    }

    //转换文件  添加水印
    transformFile(file, text = "致敬英雄") {
        //promise执行多步操作非常好用，这个过程是有一定的顺序的，你必须保证上一步完成，才能顺利进行下一步。
        //有两个参数 resolve同意的意思  reject拒绝的意思
        return new Promise((resolve) => {
            const reader = new FileReader(); //创建读取文件对象
            reader.readAsDataURL(file); //调用方法转base64
            reader.onload = () => {
                //文件读取成功完成的触发
                const canvas = document.createElement("canvas"); //新建canvas 画布
                const img = document.createElement("img"); //新建一个img标签
                img.src = reader.result; //读取结果 下载图片
                img.onload = () => {
                    //绑定事件 下载完成
                    const ctx = canvas.getContext("2d"); //获取画笔对象
                    let width = img.width; //图片的宽高
                    let height = img.height;
                    canvas.width = width; //画布的宽高
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height); //绘制图像 图片大小没变
                    ctx.fillStyle = "#ffffff"; // 设置填充字号和字体，样式
                    ctx.textBaseline = "middle";
                    ctx.textAlign = "right";
                    ctx.globalAlpha = 0.3; //透明度
                    let fs = 30
                    if (width > height) {
                        fs = width / 10
                    } else {
                        fs = height / 10
                    }
                    ctx.font = `${fs}px Arial`;
                    let text_width = ctx.measureText(text).width
                    ctx.fillText(text, width / 2 + text_width / 2, height / 2 + fs / 2)
                    //压缩图片质量 范围 0~1
                    let dataUrl = canvas.toDataURL("image/jpeg", 0.5); //输出压缩后的base64
                    //执行下一步操作  将裁剪base64的图片转换为file文件
                    resolve(this.dataURLtoFile(dataUrl, file.name));
                };
            };
        });
    }
    // 将裁剪base64的图片转换为file文件
    dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    }
    // 获取视频第一帧
    getVideoImage(file) {
        return new Promise((res, rej) => {
            if (file && file.type.indexOf("video/") == 0) {
                var video = document.createElement("video");
                video.src = URL.createObjectURL(file);
                video.addEventListener("loadeddata", function () {
                    this.currentTime = 1;
                });
                video.addEventListener("seeked", function () {
                    this.width = this.videoWidth;
                    this.height = this.videoHeight;
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    canvas.width = this.width;
                    canvas.height = this.height;
                    ctx.drawImage(this, 0, 0, canvas.width, canvas.height);
                    var image = {
                        url: canvas.toDataURL("image/jpeg", 1),
                        width: this.width,
                        height: this.height,
                        currentTime: this.currentTime,
                        duration: this.duration,
                    };
                    canvas.toBlob(function (blob) {
                        image.blob = blob;
                        res(image);
                    }, "image/jpeg");
                });
            }
        });
    }
    // 获取图片路径
    getObjectURL(file) {
        var url = null;
        if (window.createObjectURL != undefined) {
            // basic
            url = window.createObjectURL(file);
        } else if (window.URL != undefined) {
            // mozilla(firefox)
            url = window.URL.createObjectURL(file);
        } else if (window.webkitURL != undefined) {
            // webkit or chrome
            url = window.webkitURL.createObjectURL(file);
        }
        return url;
    }
    // 深拷贝
    deepCopy(obj) {
        let target = null
        if (typeof obj === 'object') {
            if (Array.isArray(obj)) { //数组
                target = [];
                obj.forEach(item => {
                    target.push(this.deepCopy(item));
                })
            } else if (obj) {
                target = {}
                let objKeys = Object.keys(obj);
                objKeys.forEach(key => {
                    target[key] = this.deepCopy(obj[key]);
                })
            } else {
                target = obj
            }
        } else {
            target = obj;
        }
        return target
    }
    getJpeg(dom) {
        const that = this
        return new Promise(resolve => {
            html2Canvas(dom).then(canvas => {
                const jpeg = canvas.toDataURL('image/jpeg', 1.0);
                // resolve(new Blob([jpeg]));
                resolve(that.base64ToFile(jpeg));
            });
        });
    }
    // 转换base64
    base64ToFile(urlData) {
        const arr = urlData.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bytes = atob(arr[1]);
        let n = bytes.length;
        const ia = new Uint8Array(n);
        while (n--) {
            ia[n] = bytes.charCodeAt(n);
        }
        return new File([ia], 'jpeg', { type: mime });
    }
    // 绘制证书
    async setCredential(para) {
        let img = require('@/assets/img/credential/zhengshu.png'),
            {
                name,
                tranches,
                competition_name,
                certificate_no,
                teacher,
                time,
                awards,
                other_name
            } = para,
            html = `<img src="${img}" alt="">
              <p class="m-flex"><span class="name">${name || ''}同学</span><span class="no m-right">证书编号：${certificate_no || ''}</span></p>
              <p>在第五届“致敬英雄”全国青少年文化艺术创作主题教育竞赛中，荣获${tranches || ''}${competition_name || ''}${awards || ''}。</p>
              <p>特发此证，以资鼓励。</p>
              <p class="m-flex m-col-top">
                <span>${other_name ? '其他成员：' : ''}</span>
                <span class="line-wrap-2 m-flex-1">${other_name || ''}</span>
              </p>
              <p class="m-flex"><span>${teacher ? '指导老师：' + teacher : ''}</span><span class="m-right">中国少年儿童文化艺术基金会</span></p>
              <p>${time[0]}年${time[1]}月</p>
              <p>文旅部登记证号（xxxxxxxx）</p>`
        let dom = document.createElement('div'),
            section = document.querySelector('section')
        dom.setAttribute('class', 'credential')
        dom.setAttribute('id', 'credential')
        dom.innerHTML = html
        section.appendChild(dom)
        let file = await this.getJpeg(document.getElementById('credential'))
        section.removeChild(dom)
        return file
    }
    async createImg(src) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.src = src
            img.setAttribute("crossOrigin", 'Anonymous')
            img.onload = () => {
                resolve(img)
            }
        })
    }
    // 绘制实践证书
    async setPractice(poster, para) {
        return new Promise(async (resolve, reject) => {
            try {
                let {
                    width,
                    height,
                    times
                } = poster, //获取画布参数
                    poster_info = {
                        font_size: {
                            p1: 6,
                            p2: 10,
                            p3: 12
                        }
                    }, //定义绘画数据
                    {
                        bg_img,
                        text,
                        color,
                        qr_code
                    } = para, {
                        font_size
                    } = poster_info

                let canvas = document.createElement('canvas'),
                    top = 0,
                    left = 0
                // 倍数
                width *= times
                height *= times
                font_size.p1 *= times
                font_size.p2 *= times
                font_size.p3 *= times
                // 画布配置
                canvas.setAttribute('width', `${width}px`)
                canvas.setAttribute('height', `${height}px`)
                const ctx = canvas.getContext('2d')
                ctx.textBaseline = 'top'
                ctx.textAlign = 'left'
                bg_img = await this.createImg(bg_img)
                await ctx.drawImage(bg_img, left, top, width, height)

                ctx.fillStyle = color.text
                ctx.font = `normal ${font_size.p3}px 方正准雅宋简体,Arial`;
                top = height / 2.2
                // 始终在标语的最左边
                left = width / 2 - font_size.p2 * 20 / 2
                ctx.fillText(text.name, left, top)
                ctx.font = `normal ${font_size.p2}px 方正准雅宋简体,Arial`;
                left += text.name.length * font_size.p3 + font_size.p2 / 4
                top += font_size.p3 - font_size.p2
                ctx.fillText('同学：', left, top)
                top += font_size.p3 + font_size.p3 / 2
                // 绘制标语
                left = width / 2 - font_size.p2 * 20 / 2
                let slogan = ['您在第五届“致敬英雄”全国青少年文化艺术创作主题教育竞赛中,创作了优秀的艺术作品,为传承和发扬红色文化做出了积极贡献。', '表现突出,特此证明。']
                for (let i in slogan) {
                    let val = slogan[i],
                        row = setContent('\n\n' + val, left, top, font_size.p2, 20)
                    top += row * font_size.p2 + font_size.p2 * 1.5
                }

                // 绘制二维码
                if (qr_code.img) {
                    ctx.font = `normal ${font_size.p1}px 方正准雅宋简体,Arial`;
                    ctx.textAlign = 'center'
                    top = height * 0.81
                    left = width / 5
                    let qrcode_img = await this.createImg(qr_code.img),
                        qrcode_size = 35 * times
                    ctx.drawImage(qrcode_img, left, top, qrcode_size, qrcode_size)
                    // 绘制二维码文字
                    top += qrcode_size + font_size.p1 / 2
                    left += qrcode_size / 2
                    ctx.fillText(qr_code.text, left, top)
                }

                // 绘制日期
                left = width * 0.73
                top = height * 0.89
                ctx.fillText('中国少年儿童文化艺术基金会', left, top)
                top += font_size.p1 * 1.5
                ctx.fillText(text.time, left, top)

                let canvas_img = canvas.toDataURL('image/png')
                resolve(canvas_img)
                // 绘制文本方法
                function setContent(content, x, y, fs, num) {
                    // content:内容 num:一行多少字 fs:字体大小
                    let content_arr = [],
                        content_text = ''
                    for (let i in content) {
                        let val = content[i]
                        content_text += val
                        console.log('绘制标语中');
                        if (i % num == 0 && i != 0) {
                            content_arr.push(content_text)
                            content_text = ''
                        } else if (i % num != 0 && i != 0 && i == content.length - 1) {
                            content_arr.push(content_text)
                            content_text = ''
                            console.log('标语准备完毕');
                        }
                    }
                    for (let i in content_arr) {
                        let val = content_arr[i]
                        if (i == 0) val = '\n\n\n\n' + val
                        ctx.fillText(val, x, y + fs * 1.5 * i)
                    }
                    return content_arr.length
                }
            } catch (error) {
                console.log('绘制报错', error);
                reject(error)
            }
        })
    }
    // 绘制省级、国家级证书
    async getCertificate(poster, para) {
        return new Promise(async (resolve, reject) => {
            try {
                let {
                    width,
                    height,
                    times,
                    id
                } = poster, //获取画布参数
                    poster_info = {
                        qrcode: {
                            img: '',
                            text: ''
                        },
                        bg_img: para.bg_img,
                        font_size: {
                            p1: 6,
                            p2: 8,
                            p3: 10,
                            p4: 12,
                            p5: 14,
                            p6: 18
                        }
                    }, //定义绘画数据
                    {
                        bg_img,
                        text,
                        color,
                        qr_code
                    } = para, {
                        font_size
                    } = poster_info
                // 倍数
                width *= times
                height *= times
                font_size.p1 *= times
                font_size.p2 *= times
                font_size.p3 *= times
                font_size.p4 *= times
                font_size.p5 *= times
                font_size.p6 *= times
                let canvas = document.createElement('canvas'),
                    top = 0,
                    left = 0
                // 画布配置
                canvas.setAttribute('width', `${width}px`)
                canvas.setAttribute('height', `${height}px`)
                const ctx = canvas.getContext('2d')
                ctx.textBaseline = 'top'
                ctx.textAlign = 'left'
                bg_img = await this.createImg(bg_img)
                await ctx.drawImage(bg_img, left, top, width, height)
                // 定义文字规则
                ctx.fillStyle = color.text
                ctx.font = `normal ${font_size.p5}px 方正准雅宋简体,Arial`;
                ctx.textAlign = 'center'
                // 绘制名字
                left = width / 2
                top += 135 * times
                ctx.fillText(text.name, left, top)
                top += font_size.p5
                // if (!text.other_name && !text.teacher||text.other_name.length==0&&!text.teacher) {
                if((!text.other_name||text.other_name.length==0||text.other_name.length==1&&text.other_name[0]=='')&&!text.teacher){
                    // 绘制横线 开发工具和真机有偏差 开发工具得除以1.25 真机除以2是正常的 可能是微信开发工具的bug吧 ╮(╯▽╰)╭
                    top += font_size.p2 / 2
                    let start = [left - ctx.measureText(text.name).width / 2, top],
                        end = [left + ctx.measureText(text.name).width / 2, top]
                    setLine(start, end, 0.5, color.text)
                }
                // 定义接下来的字体规则
                ctx.font = `normal ${font_size.p2}px 方正准雅宋简体,Arial`;
                // 绘制团队成员
                (function(){
                    if (text.other_name.length == 1 && text.other_name[0] == '') return
                    if (text.other_name.length > 0) {
                        ctx.font = 'normal bold SourceHanSansCN-Medium';
                        let other_name = []
                        for (let i in text.other_name) {
                            let val = text.other_name[i]
                            if (ctx.measureText(other_name.join(' ') + other_name[i + 1]).width > font_size
                                .p2 * 26) {
                                other_name = other_name.join(' ')
                                break
                            }
                            other_name.push(val)
                        }
                        other_name = '团队成员：' + other_name
                        // 绘制横线 开发工具和真机有偏差 开发工具得除以1.25 真机除以2是正常的 可能是微信开发工具的bug吧 ╮(╯▽╰)╭
                        let start = [left - ctx.measureText(other_name).width / 2, top],
                            end = [left + ctx.measureText(other_name).width / 2, top]
                        setLine(start, end, 0.5, color.text)
                        top += font_size.p2 / 2

                        console.log(ctx.measureText(other_name).width, other_name);
                        ctx.fillText(other_name, left, top)
                        top += font_size.p2
                    }
                })()
                // 绘制指导老师
                if (text.teacher) {
                    let teacher = '指导老师：' + text.teacher
                        if (!text.other_name || text.other_name.length == 0 || text.other_name.length == 1 && text.other_name[0] == '') {
                            // 绘制横线 开发工具和真机有偏差 开发工具得除以1.25 真机除以2是正常的 可能是微信开发工具的bug吧 ╮(╯▽╰)╭
                            let start = [left - ctx.measureText(teacher).width / 2, top],
                                end = [left + ctx.measureText(teacher).width / 2, top]
                            setLine(start, end, 0.5, color.text)
                        }
                   
                    top += font_size.p2 / 2
                    ctx.fillText(teacher, left, top)
                    top += font_size.p2
                }
                // 绘制荣获
                ctx.font = `normal ${font_size.p4}px 方正准雅宋简体,Arial`;
                top = 190 * times
                ctx.fillText('荣 获', left, top)
                top += font_size.p4
                // 绘制证书主题
                ctx.fillStyle = color.title
                ctx.font = `normal ${font_size.p3}px 方正准雅宋简体,Arial`;
                top += font_size.p3 * 0.8
                let competition_title = '第五届“致敬英雄”全国青少年文化艺术创作主题教育竞赛'
                ctx.fillText(competition_title, left, top, font_size.p3 * 23)
                top += font_size.p3

                // 绘制竞赛单元
                ctx.fillStyle = color.text
                top += font_size.p3 * 0.6
                ctx.fillText(text.cell + ' 竞赛单元', left, top)
                top += font_size.p3

                // 绘制赛区
                top += font_size.p4 * 0.6
                ctx.fillText(text.address, left, top)
                top += font_size.p4

                // 绘制几等奖
                ctx.fillStyle = color.award
                ctx.font = `normal ${font_size.p6}px 方正准雅宋简体,Arial`;
                top += font_size.p6 * 0.6
                ctx.fillText(text.award, left, top)

                if (qr_code.img) {
                    // 绘制二维码
                    top = height * 0.79
                    left = width / 5
                    let qrcode_img = await this.createImg(qr_code.img),
                        qrcode_size = 35 * times
                    ctx.drawImage(qrcode_img, left, top, qrcode_size, qrcode_size)
                    // 绘制二维码文字
                    ctx.fillStyle = color.text
                    ctx.font = `normal ${font_size.p1}px 方正准雅宋简体,Arial`;
                    top += qrcode_size + font_size.p1 / 2
                    left += qrcode_size / 2
                    ctx.fillText(qr_code.text, left, top)
                }

                // 绘制日期
                left = width * 0.73
                top = height * 0.85
                ctx.fillText('中国少年儿童文化艺术基金会', left, top)
                top += font_size.p1 * 1.5
                ctx.fillText(text.time, left, top)

                // 导出canvas
                let canvas_img = canvas.toDataURL('image/png')
                resolve(canvas_img)
                // 绘制横线
                function setLine(start, end, size, color) {
                    ctx.moveTo(start[0], start[1]);
                    ctx.lineTo(end[0], end[1]);
                    ctx.lineWidth = size;
                    ctx.strokeStyle = color;
                    ctx.stroke()
                }
            } catch (error) {
                console.log('绘制报错', error);
                reject(error)
            }
        })
    }
    //批量下载方法
    batchDownload(file_arr, file_name) {
        const zip = new JSZip()
        let works = zip.folder(file_name ? file_name + '证书' : "证书")
        file_arr.forEach((value, index) => {
            works.file(`${value.name}.jpg`, value.file, { binary: true }) // 逐个添加文件
        })
        zip.generateAsync({ type: "blob" }).then(content => { // 生成二进制流
            FileSaver.saveAs(content, `${file_name ? file_name + '证书' : '证书批量下载'}.zip`) // 利用file-saver保存文件
        })
    }
    //文件流下载
    downloadFile(file_obj) {
        const blob = file_obj.file
        const fileName = `${file_obj.name}.jpg`//后缀按需更改
        if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
        } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
        }
    }
    //批量下载方法
    downAllCertificate(file_arr) {
        const zip = new JSZip()
        for (let i = 0; i < file_arr.length; i++) {
            let val = file_arr[i],
                works = zip.folder(val.name ? `${val.name}证书${val.id}` : "证书")
            val.file.forEach(item => {
                works.file(`${val.name + item.name}.jpg`, item.file, { binary: true }) // 逐个添加文件
            })
        }
        zip.generateAsync({ type: "blob" }).then(content => { // 生成二进制流
            FileSaver.saveAs(content, `证书批量下载.zip`) // 利用file-saver保存文件
        })
    }
}
export default new variable